<template>
    <BModal
        id="modal-auto-backup"
        title="Unsaved Changes"
        centered
        cancel-title="Discard"
        cancel-variant="danger"
        ok-title="Yes"
        ok-variant="success"
        header-bg-variant="warning"
        footer-bg-variant="secondary"
        @ok="reloadBackup"
        @cancel="clearBackup"
        :visible="existingBackup"
    >
        <h4>
            You have unsaved changes while <span v-if="create">creating</span
            ><span v-if="!create">editing</span> this {{ itemName }}.
        </h4>
        <h4 class="text-danger">
            <strong>Do you want to restore your previous changes?</strong>
        </h4>
        <p>Click Discard to forget the previous changes</p>
    </BModal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import _ from "lodash";

export default {
    components: {
        BModal,
    },
    name: "OAutoBackup",
    props: {
        item: { type: Object, required: true },
        itemName: { type: String, default: "item" },
        create: { type: Boolean, required: true },
        formState: { type: Object, required: true },
        backupInterval: { type: Number, default: 5000 },
        saved: { type: Number, default: 0 },
    },
    data() {
        return {
            timeout: null,
            existingBackup: false,
            last_save_count: 0,
        };
    },
    methods: {
        getRouteName() {
            return this.$router.currentRoute.fullPath;
        },
        clearBackup(force) {
            const routeName = this.getRouteName();
            window.localStorage.removeItem(routeName, null);
            this.existingBackup = false;
        },
        backupItem() {
            if (this.formState.dirty === true) {
                if (this.item) {
                    const routeName = this.getRouteName();

                    window.localStorage.setItem(
                        routeName,
                        JSON.stringify(this.item)
                    );
                }
            }
        },
        startInterval() {
            if (this.timeout == null) {
                this.timeout = window.setInterval(
                    this.backupItem,
                    this.backupInterval
                );
            }
        },
        stopInterval() {
            if (this.timeout != null) {
                window.clearInterval(this.timeout);
                this.timeout = null;
            }
        },
        reloadBackup() {
            const routeName = this.getRouteName();

            this.existingBackup = false; // Hide the modal now.

            const valStr = window.localStorage.getItem(routeName);
            if (valStr) {
                const val = JSON.parse(valStr);

                this.formState.dirty = true; // set Dirty as we assume it was dirty last time.
                this.$emit("restore", val);
            }
        },
        clearAndStop() {
            this.clearBackup(false);
            this.stopInterval();
        },
    },
    watch: {
        item: {
            deep: true,
            handler: function (newVal, oldVal) {
                if (!_.isEmpty(oldVal)) {
                    if (!this.existingBackup) {
                        // Modal Not showing so Start backing up
                        this.startInterval();
                    }
                }
            },
        },
        saved(new_form_state_saved) {
            if (
                this.last_save_count !== new_form_state_saved &&
                new_form_state_saved > 0
            ) {
                this.clearAndStop();
                this.last_save_count = new_form_state_saved;
            }
        },
        formState: {
            deep: true,
            handler: function (newVal) {
                if (newVal != null) {
                    if (newVal.saved !== this.last_save_count) {
                        this.last_save_count = newVal.saved;

                        // Was just saved to clear and stop
                        this.clearAndStop();
                    }
                    if (newVal.closing === true) {
                        this.clearAndStop();
                    } else {
                        if (newVal.dirty === true) {
                            if (!this.existingBackup) {
                                // Only start if they have closed the modal
                                this.startInterval();
                            }
                        } else {
                            this.clearAndStop();
                        }
                    }
                }
            },
        },
    },
    computed: {
        hasBackup() {
            const routeName = this.getRouteName();

            const val = window.localStorage.getItem(routeName);
            if (val) {
                // Has a value.. anything...
                return true;
            }
            return false;
        },
        form_state_saved() {
            if (this.formState) {
                return this.formState.saved || 0;
            }

            return 0;
        },
    },
    created() {
        if (this.hasBackup) {
            this.existingBackup = true; // show Modal
        }
    },
    destroyed() {
        if (this.formState.closing === true) {
            this.clearBackup(false);
        }
        this.stopInterval();
    },
};
</script>

<style scoped></style>
