<template>
    <o-form-group
        :form-state="formState"
        :label="label"
        :message="errorMessages"
        :nolabel="nolabel"
        :type="{ 'is-danger': hasError }"
    >
        <BInputGroup v-if="!is_overriding">
            <template v-slot:append>
                <BButton @click="override" variant="outline-secondary">
                    <lock-icon />
                </BButton>
            </template>

            <BFormInput
                :class="input_class"
                @click="override"
                class="readonly_field"
                disabled
                type="number"
                v-bind="$attrs"
                v-bind:value="originalValue"
                v-on="inputListeners"
            >
                <slot />
            </BFormInput>
        </BInputGroup>

        <BInputGroup v-if="is_overriding">
            <BFormInput
                :class="input_class"
                :disabled="_is_readonly"
                type="number"
                v-bind="$attrs"
                v-bind:value="value"
                v-on="inputListeners"
            >
                <slot />
            </BFormInput>

            <template v-slot:append>
                <BButton
                    :disabled="_is_readonly"
                    @click="unoverride"
                    variant="info"
                >
                    <lock-open-variant-outline-icon />
                </BButton>
            </template>
        </BInputGroup>

        <span v-if="formState.debug">
            SE:{{ serverErrors }} [{{ serverErrorsIndex }}].{{ name }}<br />
            AL_SE:{{ all_server_errors }}
        </span>
    </o-form-group>
</template>

<script>
import { BButton, BFormInput, BInputGroup } from "bootstrap-vue";
import FormComponentMixin from "../../../mixins/form_component_mixin.js";

import LockIcon from "vue-material-design-icons/Lock.vue";
import LockOpenVariantOutlineIcon from "vue-material-design-icons/LockOpenVariantOutline.vue";

import OFormGroup from "../OFormGroup.vue";

export default {
    name: "OOverridableNumberField",
    components: {
        BInputGroup,
        BButton,
        BFormInput,
        OFormGroup,
        LockIcon,
        LockOpenVariantOutlineIcon,
    },
    mixins: [FormComponentMixin],
    data() {
        return {
            pressed_override: false,
        };
    },
    props: {
        /* NOTE maybe a string because it's JSON was a string from server */
        originalValue: { type: [Number, String], default: null },
    },
    methods: {
        override() {
            this.pressed_override = true;
            // WE are overriding this now.
            this.on_input(parseFloat(this.originalValue));
            this.on_recalc();
        },
        unoverride() {
            this.pressed_override = false;

            this.on_input(null);
            this.on_recalc();
        },
    },
    computed: {
        is_overriding() {
            if (this.pressed_override) {
                return true;
            }

            return this.value != null;
        },
    },
};
</script>

<style scoped></style>
