import Vue from "vue";

import ViewListIcon from "vue-material-design-icons/ViewList";
import PencilIcon from "vue-material-design-icons/Pencil";
import FileChartIcon from "vue-material-design-icons/FileChart";
import LinkIcon from "vue-material-design-icons/Link";

// Register the Icons so Webpack includes them and we can use them
Vue.component("ViewListIcon", ViewListIcon);
Vue.component("PencilIcon", PencilIcon);
Vue.component("FileChartIcon", FileChartIcon);
Vue.component("LinkIcon", LinkIcon);

export default [
  {
    name: "Main",
    children: [
      {
        name: "Order",
        children: [
          {
            icon: "view-list",
            title: "Orders",
            to: {
              name: "store_order.list",
              query: { limit: 25, filter: "active" },
            },
            loggedIn: true,
          },
          {
            icon: "pencil",
            title: "New Order",
            to: { name: "store_order.create" },
            loggedIn: true,
          },
        ],
        loggedIn: true,
      },
      {
        name: "Stock",
        children: [
          {
            icon: "view-list",
            title: "Stock Availability",
            to: { name: "stock.list", query: { limit: 25, filter: "active" } },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "My Stock",
            to: {
              name: "store_order_line.list",
              query: { limit: 10, filter: "incoming" },
            },
            loggedIn: true,
          },
        ],
        loggedIn: true,
      },
      {
        name: "Store",
        children: [
          {
            icon: "view-list",
            title: "Store Listing",
            to: { name: "store.list", query: { limit: 25, filter: "active" } },
            loggedIn: true,
          },
          {
            icon: "pencil",
            title: "New Store",
            to: { name: "store.create" },
            loggedIn: true,
          },
        ],
        loggedIn: true,
      },
      {
        name: "Catalogue Listing",
        children: [
          {
            icon: "view-list",
            title: "List Catalogue",
            to: {
              name: "catalogue.list",
              query: { limit: 10, filter: "active" },
            },
            loggedIn: true,
          },
          {
            icon: "pencil",
            title: "New Catalogue",
            to: { name: "catalogue.create" },
            loggedIn: true,
          },
        ],
        loggedIn: true,
      },
      {
        name: "Website And Marketing",
        children: [
          {
            icon: "view-list",
            title: "List of Website",
            to: {
              name: "website.list",
              query: {
                limit: 10000,
                filter: "whats_new",
                sort: "-website_active_start_date",
                order: "asc",
              },
            },
            loggedIn: true,
          },
          {
            icon: "link",
            title: "Marketing Material",
            to: { name: "marketing_material" },
            loggedIn: true,
          },
        ],
        loggedIn: true,
      },
      {
        name: "Suppliers",
        children: [
          {
            icon: "view-list",
            title: "Core Suppliers Listing",
            to: {
              name: "supplier.listing",
              query: { limit: 25, filter: "core_supplier", sort: "name" },
            },
            loggedIn: true,
          },
        ],
        loggedIn: true,
      },
    ],
  },
  {
    name: "Admin",
    children: [
      {
        name: "Manage",
        children: [
          {
            icon: "view-list",
            title: "Colour",
            to: {
              name: "code_colour.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "Fabric",
            to: {
              name: "code_fabric.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "Supplier",
            to: {
              name: "supplier.list",
              query: { limit: 25, filter: "active", sort: "name" },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "Product",
            to: {
              name: "product.list",
              query: { limit: 25, filter: "active" },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "Product Category",
            to: {
              name: "code_product_category.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },
        ],
        loggedIn: true,
      },
      {
        name: "Container",
        children: [
          {
            icon: "view-list",
            title: "Container",
            to: {
              name: "container.list",
              query: {
                limit: 25,
                filter: "active",
                sort: "delivery_date",
              },
            },
            loggedIn: true,
          },
          {
            icon: "pencil",
            title: "New Container",
            to: { name: "container.create" },
            loggedIn: true,
          },
        ],
        loggedIn: true,
      },
      {
        name: "Store access levels",
        children: [
          {
            icon: "view-list",
            title: "Users",
            to: {
              name: "user.list",
              query: {
                limit: 25,
                filter: "active",
                sort: "email",
                order: "asc",
              },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "Groups",
            to: {
              name: "group.list",
              query: { limit: 25, filter: "all", sort: "name", order: "asc" },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "Contact Type",
            to: {
              name: "code_contact_type.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },

          /*Uncomment to Enable.{
        icon: 'view-list',
        title: 'List Group Permissions',
        to: {name: 'group_permissions.list', query: {'limit': 25, 'filter': 'all', 'sort':'group', 'order':'asc'}},
        loggedIn: true,
      },*/
        ],
        loggedIn: true,
      },
      {
        name: "Reports",
        children: [
          {
            icon: "file-chart",
            title: "Reports",
            to: { name: "reports", params: { reportName: "NONE" } },
            loggedIn: true,
          },
        ],
      },
    ],
  },
];
