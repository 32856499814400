/* eslint-disable no-unused-vars */
import _ from "lodash";

import { mapActions, mapGetters, mapMutations } from "vuex";

import OActionButtons from "../components/forms/OActionButtons.vue";

export default function (moduleName, modelName) {
    return {
        props: {
            create: { type: Boolean, default: false },
        },
        components: {
            OActionButtons,
        },
        methods: {
            ...mapMutations(["showMessage"]),
            ...mapActions(moduleName + "Module", [
                "getById",
                "createNew",
                "saveItem",
                "deleteItem",
            ]),

            reset() {
                if (this.formState.dirty) {
                    this.clean = false;
                }
            },
            enableDebug(e) {
                if (e.shiftKey) {
                    this.formState.debug = !this.formState.debug;
                }
            },
            catchError(result) {
                var response = result.response;
                if (response) {
                    if ("status" in response && response.status === 400) {
                        this.clean = true;
                        this.serverErrors = _.clone(response.data);
                    }
                }

                let msg = "An error has occurred!";
                this.showMessage({ message: msg, type: "danger", show: true });
                return result;
            },
            _doSave(okHandler) {
                let vm = this;

                var item = this.item;
                var onBeforeSave = vm["OnBeforeSave"];
                if (onBeforeSave) {
                    item = onBeforeSave(this.item);
                }

                this.saveItem(item)
                    .then((result) => {
                        vm.$set(vm, "savedCount", (vm.savedCount || 0) + 1); // How many times it has been SAVED since opening

                        var msg = vm._getFormConfig.updated_msg;
                        if (vm.create) {
                            msg = vm._getFormConfig.created_msg;
                        }

                        vm.original_item = _.cloneDeep(result); // Copy out of vuex

                        vm.showMessage({
                            message: msg,
                            type: "success",
                            show: true,
                        });
                        vm.serverErrors = {};
                        vm.$set(vm, "clean", true);

                        vm.$set(vm.formState, "dirty", false); // Reset to Not dirty as we Just saved
                        vm.$set(vm.formState, "saved", vm.savedCount || 0);

                        if ("hookSave" in vm) {
                            // call hookSave if there is a method here
                            vm.hookSave(result, vm.create);
                        }

                        if (okHandler) {
                            vm.$nextTick(function () {
                                okHandler(result);
                            });
                        }
                    })
                    .catch(this.catchError);
            },
            doSave() {
                let vm = this;
                this._doSave(function (result) {
                    if (vm.create) {
                        vm.$router.replace({
                            name: modelName + ".edit",
                            params: { id: result.id },
                        });
                    } else {
                        //Deep-copy to fix console errors on vuex mutations
                        vm.item = _.clone(result); // Copy out of vuex
                    }
                });
            },
            doClose() {
                this.$set(this.formState, "closing", true);
                this.$router.go(-1);
            },
            doSaveAndClose() {
                let vm = this;

                this._doSave(function (result) {
                    vm.doClose();
                });
            },
            doSaveAndNew() {
                let vm = this;
                this._doSave(function (result) {
                    vm.doCreateNew();
                    const query = vm.$router.currentRoute.query;
                    vm.$router
                        .replace({
                            name: modelName + ".create",
                            query: query,
                        })
                        .catch((err) => {
                            window.lockLeave = false;
                            location.reload();
                        });
                });
            },
            doCreateNew() {
                const vm = this;
                this.createNew().then((result) => {
                    vm.item = _.clone(result); // Copy out of vuex
                    vm.$set(vm, "clean", true);

                    /* Capture query params that start with _ and use them to set defaults so we could do
                      Something like model/create?_name=DefaultName
                      And set the "name" property as "DefaultName" when creating the new item
                     */
                    _.forEach(vm.$route.query, function (val, key) {
                        if (key.startsWith("_")) {
                            key = key.substring(1);

                            vm.$set(vm.item, key, val);
                        }
                    });

                    if (vm.OnCreateNew) {
                        // if there is a function setDefaults then use that to set the defaults for this item
                        vm.OnCreateNew(vm.item);
                    }
                    vm.$set(vm.formState, "saved", 0);
                });
            },
            doDeleteItem() {
                let vm = this;

                this.deleteItem(this.item).then((result) => {
                    if ("hookDelete" in vm) {
                        // call hookDelete if there is a method here
                        vm.hookDelete(result);
                    }

                    vm.formState.dirty = false;
                    vm.doClose();
                });
            },
            unlockForm() {
                if (this.formState) {
                    this.$set(this.formState, "locked", false);
                }
            },
            getItem(id) {
                const vm = this;
                this.getById(id).then((result) => {
                    this.item = _.cloneDeep(result); // Copy out of vuex
                    this.original_item = _.cloneDeep(result); // Copy out of vuex
                    this.clean = true;
                    if ("onLoaded" in vm) {
                        this.onLoaded(vm.item);
                    }
                });
            },
            reload() {
                if (!this.create) {
                    // Only if not creating!
                    this.getItem(this.item.id);
                }
            },
        },
        computed: {
            ...mapGetters(moduleName + "Module", ["working"]),
            _getFormConfig() {
                return {
                    created_msg: "Created Item",
                    updated_msg: "Updated Item",
                };
            },
        },
        created() {
            if (!this.create) {
                this.getItem(this.$route.params.id);
            } else {
                this.doCreateNew();
            }
        },
        mounted() {
            window.lockLeave = true; // show prevent message
        },
        data: () => ({
            item: {},
            /* the Item we loaded - this should be READONLY and not edited */
            original_item: {},

            serverErrors: {},

            savedCount: 0, // 0 at Str

            clean: true, // add here so we can watch it

            formState: {
                dirty: false,
                invalid: false,
                error: false,
                init: true,
                closing: false /* Set to true when closing */,
                debug: false,
                saved: 0 /* Number of times this form was saved */,
                locked: false /* Default to unlocked */,
                //Remove
                valid: true,
            },
        }),
    };
}
