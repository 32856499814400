const SupplierList = () =>
  import(/* webpackChunkName: "main" */ "./pages/ListPage.vue");
const SupplierDetail = () =>
  import(/* webpackChunkName: "main" */ "./pages/FormPage.vue");
const SupplierIndex = () =>
  import(/* webpackChunkName: "main" */ "./pages/IndexPage.vue");
const SupplierListing = () =>
  import(/* webpackChunkName: "main" */ "./pages/SupplierListing.vue");

export default [
  {
    path: "/supplier",
    component: SupplierIndex,
    redirect: "/supplier/list",
    name: "supplier",
    children: [
      {
        path: "list",
        component: SupplierList,
        name: "supplier.list",
      },
      {
        path: "create",
        component: SupplierDetail,
        name: "supplier.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: SupplierDetail,
        name: "supplier.edit",
        props: { create: false },
      },
      {
        path: "/supplier-listing",
        component: SupplierListing,
        name: "supplier.listing",
      },
    ],
  },
];
