import CodeColour from "./code_colour.js";
import CodeFabric from "./code_fabric.js";
import StoreOrderLine from "./store_order_line.js";
import Product from "./product.js";
import CodeContactType from "./code_contact_type.js";
import StoreContact from "./store_contact.js";
import Store from "./store.js";
import Container from "./container.js";
import Stock from "./stock.js";
import StoreOrder from "./store_order.js";
import Supplier from "./supplier.js";
import CodeProductCategory from "./code_product_category.js";
import CataloguePage from "./catalogue_page.js";
import Catalogue from "./catalogue.js";

import { default as PermissionSchema } from "../../vue-ocom/grouppermissions/schema/index.js";

export default {
  CodeColour,
  CodeFabric,
  StoreOrderLine,
  Product,
  CodeContactType,
  StoreContact,
  Store,
  Container,
  Stock,
  StoreOrder,
  Supplier,
  CodeProductCategory,
  CataloguePage,
  Catalogue,

  ...PermissionSchema /* For Schema */,
};
