const StockAvailabilityList = () =>
  import(/* webpackChunkName: "main" */ "./pages/ListAvailabilityPage.vue");
const StockIndex = () =>
  import(/* webpackChunkName: "main" */ "./pages/IndexPage.vue");

export default [
  {
    path: "/stock",
    component: StockIndex,
    redirect: "/stock/list",
    name: "stock",
    children: [
      {
        path: "list",
        component: StockAvailabilityList,
        name: "stock.list",
      },
    ],
  },
];
