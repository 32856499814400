<template>
    <span :class="get_class">{{ display_value }}</span>
</template>

<script>
import DisplayMixin from "../../mixins/display_mixin.js";

export default {
    name: "NumberDisplay",
    // props: ["value", "decimals", "default", "defaultClass", "toLocale"],
    props: {
        value: {},
        decimals: {},
        default: { type: String, default: "" },
        toLocale: { type: Boolean, default: false },
        locale: { type: String, default: undefined },
        prefix: { type: String, default: "" },
        suffix: { type: String, default: "" },
    },
    mixins: [DisplayMixin("display_value")],
    computed: {
        display_value() {
            if (this.value) {
                let decimals = this.decimals;
                if (!decimals) {
                    decimals = 0;
                }

                let result = +this.value;

                if (this.toLocale) {
                    result = (+result).toLocaleString(this.locale, {
                        minimumFractionDigits: decimals,
                    });
                } else {
                    result = result.toFixed(decimals); // Just format to Decimal Places
                }

                return this.prefix + result + this.suffix;
            }

            return this.default;
        },
    },
};
</script>

<style scoped></style>
