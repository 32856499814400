<template>
    <o-form-wrapper
        :form-state="formState"
        :schema="schema"
        :server-errors="_se"
        :v="$v.item"
        v-model="item"
    >
        <div class="row no-gutters justify-content-end">
            <div class="col-lg-11 col-12 d-lg-flex">
                <div class="col-lg-6 col-12">
                    <o-widget-wrapper
                        :form-state="formState"
                        :schema="schema"
                        :server-errors="_se"
                        :validators="$v.item"
                        name="first_name"
                    >
                        <o-text-field
                            label="Name *"
                            :form-state="formState"
                            placeholder="First Name"
                            :server-errors="_se"
                            :validators="$v.item"
                            name="first_name"
                            v-bind="schema.fields.first_name"
                            v-model="item.first_name"
                        ></o-text-field>
                    </o-widget-wrapper>
                </div>
                <div class="col-lg-6 col-12">
                    <o-widget-wrapper
                        :form-state="formState"
                        :schema="schema"
                        :server-errors="_se"
                        :validators="$v.item"
                        name="last_name"
                    >
                        <o-text-field
                            nolabel
                            :form-state="formState"
                            placeholder="Last Name"
                            :server-errors="_se"
                            :validators="$v.item"
                            name="last_name"
                            v-bind="schema.fields.last_name"
                            v-model="item.last_name"
                        ></o-text-field>
                    </o-widget-wrapper>
                </div>
            </div>
        </div>

        <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="email"
        >
            <o-text-field
                :form-state="formState"
                :server-errors="_se"
                :validators="$v.item"
                name="email"
                v-bind="schema.fields.email"
                v-model="item.email"
            ></o-text-field>
        </o-widget-wrapper>

        <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="new_password"
        >
            <o-text-field
                :form-state="formState"
                :server-errors="_se"
                :validators="$v.item"
                name="new_password"
                v-bind="schema.fields.new_password"
                v-model="newPassword"
                @input="newTypedPassword"
                v-if="!complexity_checking"
            ></o-text-field>
            <o-text-field
                :form-state="formState"
                :server-errors="_se"
                :validators="$v.item"
                :value="newPassword"
                name="new_password"
                v-bind="schema.fields.new_password"
                v-model="newPassword"
                @input="newTypedPassword"
                v-if="complexity_checking"
            ></o-text-field>
            <password
                class="password-complexity"
                v-model="newPassword"
                :strength-meter-only="true"
                v-if="complexity_checking"
            >
            </password>
        </o-widget-wrapper>
        <div class="">
            <div class="col-sm-12 col-md-12 col-lg-12 generate-password">
                <o-generate @on-generate="generatePassword"></o-generate>
            </div>
        </div>
    </o-form-wrapper>
</template>

<script>
import schema from "../../schema/index.js";
import { cleanUpModel } from "../../../schema/schema_vuetify.js";
import {
    CreateFormStateMixin,
    CreateGroupPermissionMixin,
    VuelidateMixin,
} from "../../../mixins";
import { validationMixin } from "vuelidate";
import OWidgetWrapper from "../../../components/forms/OWidgetWrapper.vue";
import OTextField from "../../../components/forms/fields/OTextField.vue";
import OSelect from "../../../components/forms/fields/OSelect.vue";
import OCheckbox from "../../../components/forms/fields/OCheckbox.vue";
import OFormWrapper from "../../../components/forms/OFormWrapper.vue";
import ODateTime from "../../../components/forms/fields/ODateTime.vue";
import Password from "vue-password-strength-meter";

import * as CONFIG from "../../../../config.js";

import OGenerate from "../../../components/forms/OGenerate.vue";

export default {
    mixins: [
        VuelidateMixin,
        validationMixin,
        CreateFormStateMixin("$v"),
        CreateGroupPermissionMixin("User"),
    ],
    components: {
        OFormWrapper,
        OWidgetWrapper,
        ODateTime,

        OCheckbox,
        OSelect,
        OTextField,
        Password,
        OGenerate,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        level: { type: Number, default: 0 },
        inModal: { type: Boolean, default: false },
        advancedUsage: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        schemaModel: cleanUpModel(schema.User, {}),
        newPassword: "",
    }),
    methods: {
        newTypedPassword() {
            const vm = this;
            vm.formState.dirty = true;
            vm.item.new_password = vm.newPassword;
        },
        generatePassword(password) {
            const vm = this;
            vm.item.new_password = password;
            vm.newPassword = password;
            vm.formState.dirty = true;
        },
    },
    computed: {
        _se() {
            // Return an empty Object if there are no Server Errors to avoid Binding Errors
            return this.serverErrors || {};
        },
        complexity_checking() {
            return CONFIG.TurnOnComplexityChecking === true;
        },
    },
    watch: {},
    created() {},
};
</script>
<style scoped>
.password-complexity {
    max-width: 705px !important;
}
.generate-password {
    margin-left: 167px !important;
}
</style>
