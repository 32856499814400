const ProductList = () =>
  import(/* webpackChunkName: "main" */ "./pages/ListPage.vue");
const ProductDetail = () =>
  import(/* webpackChunkName: "main" */ "./pages/FormPage.vue");
const ProductIndex = () =>
  import(/* webpackChunkName: "main" */ "./pages/IndexPage.vue");

const WebsiteListing = () =>
  import(/* webpackChunkName: "main" */ "./pages/WebsiteListing.vue");

export default [
  {
    path: "/product",
    component: ProductIndex,
    redirect: "/product/list",
    name: "product",
    children: [
      {
        path: "list",
        component: ProductList,
        name: "product.list",
      },
      {
        path: "create",
        component: ProductDetail,
        name: "product.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: ProductDetail,
        name: "product.edit",
        props: { create: false },
      },
      {
        path: "/product/website-listing",
        component: WebsiteListing,
        name: "website.list",
      },
    ],
  },
];
