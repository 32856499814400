<template>
  <LoginGuard>
    <div class="container">
      <o-nav-dashboard-menu :items="items" />
    </div>
  </LoginGuard>
</template>

<script>
import LoginGuard from "../vue-ocom/auth/components/LoginGuard.vue";
import ONavDashboardMenu from "@/vue-ocom/components/layout/app/ONavDashboardMenu";
import MENU_ITEMS from "./menu_items.js";

export default {
  name: "HomePage",
  components: {
    LoginGuard,
    ONavDashboardMenu,
  },
  data: () => ({
    items: MENU_ITEMS,
  }),
};
</script>
