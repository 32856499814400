import Vue from "vue";
import Vuex from "vuex";
import AuthModule from "./vue-ocom/auth/store";
import AxiosAuthPlugin from "./vue-ocom/auth/store/plugin.js";
import AppModules from "./app/store.js";
import GroupPermissionsModules from "./vue-ocom/grouppermissions/store.js";
import CreateUserModule from "./vue-ocom/store/CreateUserModule.js";
import * as CONFIG from "./config.js";

var root_url = CONFIG.root_url;
const UserModule = CreateUserModule(root_url);

Vue.use(Vuex);

const allModules = Object.assign(
  {
    AuthModule,
    UserModule,
  },
  AppModules,
  GroupPermissionsModules
);

export default new Vuex.Store({
  plugins: [AxiosAuthPlugin],
  modules: allModules,
  strict: CONFIG.Debug,
  state: {
    messages: {
      show: false,
    },
  },
  mutations: {
    showMessage(state, messages) {
      console.log(messages);

      state.messages = messages;
    },
  },
  actions: {},
  getters: {
    messages(state) {
      return state.messages || { show: false };
    },
  },
});
