import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import * as CONFIG from "@/config.js";

/* Create mapActions and mapGetters for a RestModule (and similar modules)
for Code Modules. And loads them!

moduleList is an array of Module names but assume moduleList has the value
['Test']

Then the following actions and getters are created

mapActions({
    createNewTest: 'TestModule/createNew',
    saveTestItem: 'TestModule/saveItem',
    getTestById: 'TestModule/getById',
    patchTestItem: 'TestModule/pathItem',

    getAllTest: 'TestModule/getAll', //called by loadCodeTables and when created
});

mapGetters({
    optionTestItems: 'TestModule/code_items',
    optionTestItems: 'TestModule/code_itemsByID',
    optionTestWorking: 'TestModule/working',
    optionTestItemsById: 'TestModule/itemsByID',
});
 */

export default function (moduleList, list_options) {
    var actionList = {};
    var fetchActionList = {};
    var getterList = {};

    var defaultLimit = CONFIG.DEFAULT_CODE_LIMIT || 2000;
    var defaultListOptions = list_options || {
        limit: defaultLimit,
        offset: 0,
        _code: true,
    };

    _.each(moduleList, function (moduleName) {
        actionList["createNew" + moduleName] = moduleName + "Module/createNew";
        actionList["save" + moduleName + "Item"] =
            moduleName + "Module/saveItem";
        actionList["get" + moduleName + "ById"] = moduleName + "Module/getById";
        actionList["patch" + moduleName + "Item"] =
            moduleName + "Module/patchItem";

        fetchActionList["getAll" + moduleName] = moduleName + "Module/getAll";

        getterList["option" + moduleName + "Items"] =
            moduleName + "Module/code_items";
        getterList["option" + moduleName + "ItemsById"] =
            moduleName + "Module/code_itemsByID";
        getterList["option" + moduleName + "Working"] =
            moduleName + "Module/working";
        getterList["get" + moduleName + "NewItem"] = moduleName + "Module/item";
    });

    /* With a Module name of "Customer" create a Method GetCustomerCodeList(list_options)
       That can be called to load the code options
     */

    var getCodeTablesMethods = {};
    _.each(moduleList, function (moduleName) {
        var function_name = "Get" + moduleName + "CodeList";

        getCodeTablesMethods[function_name] = function (list_options) {
            var vm = this;

            list_options = {
                _code: true /* this could be overwritten below*/,
                ...defaultListOptions,
                ...list_options,
            }; // Get 2000 items - should be all if not we need to use another method
            var onBeforeFilterList = vm["OnBefore" + function_name];
            var onAfterFilterList = vm["OnAfter" + function_name];

            if (onBeforeFilterList) onBeforeFilterList(list_options);
            vm["getAll" + moduleName]({ ...list_options }).then(function (
                results
            ) {
                if (onAfterFilterList) {
                    onAfterFilterList(results);
                }
            });
        };
    });

    return {
        methods: {
            ...getCodeTablesMethods,
            loadCodeTables() {
                const vm = this;

                console.log(
                    "This code is using 'CreateCodeTableFetchMixin' please replace with 'UseCodeModulesMixin' on the 'component' and 'FetchCodeMixin' for the 'Page' components. Use Builder to generate"
                );

                // Load all
                _.each(moduleList, function (moduleName) {
                    vm["Get" + moduleName + "CodeList"]();
                });
            },
            ...mapActions({ ...fetchActionList, ...actionList }),
        },
        computed: {
            ...mapGetters(getterList),
        },
        created() {
            this.loadCodeTables();
        },
    };
}
