const CatalogueList = () =>
  import(/* webpackChunkName: "main_app" */ "./pages/ListPage.vue");
const CatalogueDetail = () =>
  import(/* webpackChunkName: "main_app" */ "./pages/FormPage.vue");
const CatalogueIndex = () =>
  import(/* webpackChunkName: "main_app" */ "./pages/IndexPage.vue");

const CataloguePages = () =>
  import(/* webpackChunkName: "main_app" */ "./pages/CataloguePages.vue");

export default [
  {
    path: "/catalogue",
    component: CatalogueIndex,
    redirect: "/catalogue/list",
    name: "catalogue",
    children: [
      {
        path: "list",
        component: CatalogueList,
        name: "catalogue.list",
      },
      {
        path: "create",
        component: CatalogueDetail,
        name: "catalogue.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CatalogueDetail,
        name: "catalogue.edit",
        props: { create: false },
      },
      {
        path: ":id/catalogue-pages",
        component: CataloguePages,
        name: "catalogue_pages.edit",
        props: { create: false },
      },
    ],
  },
];
