const CataloguePageList = () =>
  import(/* webpackChunkName: "main_app" */ "./pages/ListPage.vue");
const CataloguePageDetail = () =>
  import(/* webpackChunkName: "main_app" */ "./pages/FormPage.vue");
const CataloguePageIndex = () =>
  import(/* webpackChunkName: "main_app" */ "./pages/IndexPage.vue");

export default [
  {
    path: "/catalogue_page",
    component: CataloguePageIndex,
    redirect: "/catalogue_page/list",
    name: "catalogue_page",
    children: [
      {
        path: "list",
        component: CataloguePageList,
        name: "catalogue_page.list",
      },
      {
        path: "create",
        component: CataloguePageDetail,
        name: "catalogue_page.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CataloguePageDetail,
        name: "catalogue_page.edit",
        props: { create: false },
      },
    ],
  },
];
