import RestModule from "../vue-ocom/store/RestModule.js";
import schema from "./schema/index.js";
import CreateUserModule from "../vue-ocom/store/CreateUserModule.js";
import ReadOnlyRestModule from "vue-ocom/store/ReadOnlyRestModule.js";
import CreateReport from "../vue-ocom/reports/ReportsModule.js";

var root_url = process.env.VUE_APP_ROOT_API;

const UserModule = CreateUserModule(root_url);
const ReportsModule = CreateReport(root_url);

const CodeColourModule = RestModule(
  root_url + "/api/code_colour/",
  schema.CodeColour,
  true
);
const CodeFabricModule = RestModule(
  root_url + "/api/code_fabric/",
  schema.CodeFabric,
  true
);
const CodeContactTypeModule = RestModule(
  root_url + "/api/code_contact_type/",
  schema.CodeContactType,
  true
);

const StoreOrderLineModule = RestModule(
  root_url + "/api/store_order_line/",
  schema.StoreOrderLine,
  true
);
const ProductModule = RestModule(
  root_url + "/api/product/",
  schema.Product,
  true
);

const StoreContactModule = RestModule(
  root_url + "/api/store_contact/",
  schema.StoreContact,
  true
);
const StoreModule = RestModule(root_url + "/api/store/", schema.Store, true);
const ContainerModule = RestModule(
  root_url + "/api/container/",
  schema.Container,
  true
);
const StockModule = RestModule(root_url + "/api/stock/", schema.Stock, true);
const StoreOrderModule = RestModule(
  root_url + "/api/store_order/",
  schema.StoreOrder,
  true
);
//Add more modules here if needed. These will automatically be included and available in main store.
const StockAvailabilityModule = ReadOnlyRestModule(
  root_url + "/api/stock_availability/",
  schema.Stock,
  true /* Not used so not correct */
);

const SupplierModule = RestModule(
  root_url + "/api/supplier/",
  schema.Supplier,
  true
);
const CodeProductCategoryModule = RestModule(
  root_url + "/api/code_product_category/",
  schema.CodeProductCategory,
  true
);
const CataloguePageModule = RestModule(
  root_url + "/api/catalogue_page/",
  schema.CataloguePage,
  true
);
const CatalogueModule = RestModule(
  root_url + "/api/catalogue/",
  schema.Catalogue,
  true
);

export default {
  UserModule,

  CodeColourModule,
  CodeFabricModule,
  StoreOrderLineModule,
  ProductModule,
  CodeContactTypeModule,
  StoreContactModule,
  StoreModule,
  ContainerModule,
  StockModule,
  StoreOrderModule,
  //Add more modules here
  StockAvailabilityModule,
  ReportsModule,
  SupplierModule,
  CodeProductCategoryModule,
  CataloguePageModule,
  CatalogueModule,
};
