import { Field, FIELD_TYPES, Model } from "../../vue-ocom/schema";

export default Model("store_order_line", {
  verbose_name: "Store Order Line",
  verbose_name_plural: "Store Order Lines",
  fields: [
    Field("id", {
      label: "ID",
      primary_key: true,
      readonly: true,
      hide: false,
      field_type: FIELD_TYPES.AutoNumber,
    }),

    Field("active_start_date", {
      label: "Active Start Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return new Date();
      },
    }),
    Field("active_end_date", {
      label: "Active End Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("display_order", {
      label: "Display Order",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return 0;
      },
    }),
    Field("stock_id__product__name", {
      label: "Product Name",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      ref_field_type: "Product Name",
      ref_display_field: "stock_id",
      default: () => {
        return null;
      },
    }),
    Field("stock_id__product__code", {
      label: "Product Code",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      ref_field_type: "Product Code",
      ref_display_field: "stock_id",
      default: () => {
        return null;
      },
    }),
    Field("stock_id__product__description", {
      label: "Product Description",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),

    Field("store_order__container__code", {
      label: "Container Code",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),

    Field("store_order_store_order_lines__id", {
      label: "Order Number",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),

    Field("store_order_store_order_lines__container__name", {
      label: "Container Name",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),

    Field("stock_id__product__colour__description", {
      label: "Colour",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),

    Field("stock_id__product__fabric__description", {
      label: "Fabric",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),

    Field("store_order_store_order_lines__container__delivery_date", {
      label: "Expected Delivery Date",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),

    Field("modified_date", {
      label: "Modified Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("created_date", {
      label: "Created Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("quantity", {
      label: "Quantity",
      primary_key: false,
      readonly: false,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("notes", {
      label: "Notes",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
    Field("stock_id", {
      label: "StockID",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Stock",
      ref_display_field: "price",
      default: () => {
        return null;
      },
    }),
    Field("stock_id__price", {
      label: "Unit Price",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("stock_total_price", {
      label: "Total Price",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
  ],
});
