import { Field, FIELD_TYPES, Model } from "../../vue-ocom/schema";

export default Model("product", {
  verbose_name: "Product",
  verbose_name_plural: "Products",
  fields: [
    Field("id", {
      label: "ID",
      primary_key: true,
      readonly: true,
      hide: false,
      field_type: FIELD_TYPES.AutoNumber,
    }),

    Field("active_start_date", {
      label: "Active Start Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return new Date();
      },
    }),
    Field("active_end_date", {
      label: "Active End Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("description", {
      label: "Description",
      primary_key: false,
      readonly: false,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
    Field("code", {
      label: "Code",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
    Field("colour", {
      label: "Colour",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeColour",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("colour__description", {
      label: "Colour",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("fabric", {
      label: "Fabric",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeFabric",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("fabric__description", {
      label: "Fabric",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("supplier", {
      label: "Supplier",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Supplier",
      ref_display_field: "name",
      default: () => {
        return null;
      },
    }),
    Field("supplier__name", {
      label: "Supplier",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("is_shipped", {
      label: "Is Shipped",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Boolean,
      default: () => {
        return false;
      },
    }),
    Field("star_bonus", {
      label: "Star Bonus",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Boolean,
      default: () => {
        return false;
      },
    }),
    Field("notes", {
      label: "Notes",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
    Field("wholesale_price", {
      label: "Wholesale Price",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("region_1_rrp", {
      label: "Region 1 RRP",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("website_active_start_date", {
      label: "Website Active Start Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("website_active_end_date", {
      label: "Website Active End Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("category", {
      label: "Category",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeProductCategory",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("category__description", {
      label: "Category",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("website_url", {
      label: "Website URL",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
    Field("categories", {
      label: "Categories",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.ManyToMany,
      keys_only: true /* The field is just ids */,
      ref_field_type: "CodeProductCategory",
      default: () => {
        return [];
      },
    }),
    Field("region_2_rrp", {
      label: "Region 2 RRP",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("region_3_rrp", {
      label: "Region 3 RRP",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      }
    }),
    Field("region_4_rrp", {
      label: "Region 4 RRP",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      }
    }),
  ],
});
