import { render, staticRenderFns } from "./OTextInput.vue?vue&type=template&id=7033b674&scoped=true&"
import script from "./OTextInput.vue?vue&type=script&lang=js&"
export * from "./OTextInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7033b674",
  null
  
)

export default component.exports