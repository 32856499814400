import { Field, FIELD_TYPES, Model } from "../../vue-ocom/schema";

export default Model("catalogue_page", {
  verbose_name: "Catalogue Page",
  verbose_name_plural: "Catalogue Pages",
  fields: [
    Field("id", {
      label: "ID",
      primary_key: true,
      readonly: true,
      hide: false,
      field_type: FIELD_TYPES.AutoNumber,
    }),

    Field("page_number", {
      label: "Page Number",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("letter_code", {
      label: "Letter Code",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
    Field("product", {
      label: "Product",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Product",
      ref_display_field: "description",
      default: () => {
        return;
      },
    }),
    Field("product__description", {
      label: "Product",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),

    Field("notes", {
      label: "Notes",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
    Field("cost", {
      label: "Cost",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("region_1_rrp", {
      label: "Region 1 RRP",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("region_2_rrp", {
      label: "Region 2 RRP",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("region_3_rrp", {
      label: "Region 3 RRP",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      }
    }),
    Field("region_4_rrp", {
      label: "Region 4 RRP",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      }
    }),
    Field("display_order", {
      label: "Display Order",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("new_price", {
      label: "New Price",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Boolean,
      default: () => {
        return false;
      },
    }),
  ],
});
