import { Field, FIELD_TYPES, Model } from "../../vue-ocom/schema";

export default Model("store_order", {
  verbose_name: "Store Order",
  verbose_name_plural: "Store Order",
  fields: [
    Field("id", {
      label: "Order Number",
      primary_key: true,
      readonly: true,
      hide: false,
      field_type: FIELD_TYPES.AutoNumber,
    }),

    Field("active_start_date", {
      label: "Active Start Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return new Date();
      },
    }),
    Field("active_end_date", {
      label: "Active End Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("created_date", {
      label: "Created Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("modified_date", {
      label: "Modified Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("store", {
      label: "Store",
      primary_key: false,
      readonly: false,
      required: true,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Store",
      ref_display_field: "name",
      default: () => {
        return null;
      },
    }),
    Field("store__name", {
      label: "Store",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("store_order_lines", {
      label: "Store Order Lines",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.ManyToMany,
      ref_field_type: "StoreOrderLine",
      default: () => {
        return [];
      },
    }),
    Field("notes", {
      label: "Notes",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
    Field("container", {
      label: "Container",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Container",
      ref_display_field: "code",
      default: () => {
        return null;
      },
    }),
    Field("container__code", {
      label: "Container",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("container_code", {
      label: "Container Code",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Container",
      ref_display_field: "code",
      default: () => {
        return null;
      },
    }),
    Field("container__name", {
      label: "Container Name",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Container",
      ref_display_field: "name",
      default: () => {
        return null;
      },
    }),
    Field("container__delivery_date", {
      label: "Expected Delivery Date",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Container",
      ref_display_field: "delivery_date",
      default: () => {
        return null;
      },
    }),
    Field("product_code", {
      label: "Product Code",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Product",
      ref_display_field: "code",
      default: () => {
        return null;
      },
    }),
    Field("product_name", {
      label: "Product Name",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Product",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("colour", {
      label: "Colour",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeColour",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("fabric", {
      label: "Fabric",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeFabric",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("store_order_lines__stock_id__product__supplier__name", {
      label: "Supplier",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
    Field("store_order_lines__stock_id__product__supplier__phone_number", {
      label: "Supplier phone number",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
  ],
});
