const StoreContactList = () =>
  import(/* webpackChunkName: "main" */ "./pages/ListPage.vue");
const StoreContactDetail = () =>
  import(/* webpackChunkName: "main" */ "./pages/FormPage.vue");
const StoreContactIndex = () =>
  import(/* webpackChunkName: "main" */ "./pages/IndexPage.vue");

export default [
  {
    path: "/store_contact",
    component: StoreContactIndex,
    redirect: "/store_contact/list",
    name: "store_contact",
    children: [
      {
        path: "list",
        component: StoreContactList,
        name: "store_contact.list",
      },
      {
        path: "create",
        component: StoreContactDetail,
        name: "store_contact.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: StoreContactDetail,
        name: "store_contact.edit",
        props: { create: false },
      },
    ],
  },
];
