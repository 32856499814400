<template>
    <div class="input-group">
        <slot name="prepend"></slot>
        <flat-pickr
            :class="input_class"
            :config="_config"
            :disabled="disabled"
            :placeholder="placeholder"
            :value="date"
            @on-change="changed_date"
            @input="input_changed"
            :name="name"
        >
        </flat-pickr>

        <div class="input-group-btn" v-if="!noButtons">
            <button
                :disabled="disabled"
                class="btn btn-default"
                data-toggle
                title="Toggle"
                type="button"
            >
                <calendar-icon />
            </button>
            <button
                :disabled="disabled"
                class="btn btn-default"
                data-clear
                title="Clear"
                type="button"
            >
                <close-icon />
            </button>
        </div>
        <slot name="append"></slot>
    </div>
</template>

<script>
import moment from "moment";
import "moment-timezone";
import CloseIcon from "vue-material-design-icons/Close.vue";
import CalendarIcon from "vue-material-design-icons/Calendar.vue";
import { mergeCss } from "../../../../utils/css.js";

/*
    This component wraps the vue-flatpickr-component  so we can make it work the way we want

    Why have this as well as ODateTime? So we can reuse this component without having the Server errors stuff
     */
export default {
    name: "OFlatpickrWrapper",
    components: { CalendarIcon, CloseIcon },
    model: {
        value: "value",
        event: "input",
    },
    props: {
        value: { type: [Date, String], default: null },
        hideTime: { type: Boolean, default: false },
        includeTime: {
            type: Boolean,
            default: false,
        } /* if hideTime is true then add 'T00:00:00.000Z' to date for DateTimeField shown as Date Only */,
        hideDate: { type: Boolean, default: false },
        config: {
            type: Object,
            default: () => {},
        },
        noButtons: { type: Boolean, default: false },
        state: { type: Boolean, default: null },
        inline: { type: Boolean, default: false },
        placeholder: { type: String, default: "Select date" },
        name: { type: String, default: "datetime" },
        disabled: { type: Boolean, default: false },
        input_class: {
            type: [Array, String, Object],
            default: () => {},
        },
        firstDayOfWeek: { type: Number, default: 0 },
        minDate: { type: String | Date, default: "" },
        maxDate: { type: String | Date, default: "" },
    },
    data() {
        return {
            invalidInput: false,
        };
    },
    computed: {
        _classes() {
            return mergeCss(this.class, { "d-none": this.inline });
        },
        date() {
            // format or process if required..
            return this.value;
        },
        mode() {
            const vm = this;

            if (!this.hideTime && !this.hideDate) {
                // Date and Time
                return {
                    name: "datetime",
                    config: {
                        altFormat: "j/n/Y h:i K",
                    },
                    formatForModel(value) {
                        return moment(value).toISOString(); // Full ISO String
                    },
                    parseInput(value) {
                        const momentVal = moment(value, "DD/MM/YYYY hh:mm A");
                        return momentVal;
                    },
                };
            }
            if (this.hideTime && !this.hideDate) {
                // Date Only
                return {
                    name: "date",
                    config: {
                        altFormat: "j/n/Y",
                    },
                    formatForModel(value) {
                        var suffix = "";
                        if (vm.includeTime) {
                            // Add a Midnight value if the field is DateTimeField but should just enter a date.
                            suffix = "T00:00:00.000Z";
                        }
                        return moment(value).format("YYYY-MM-DD") + suffix; // Date only
                    },
                    parseInput(value) {
                        const momentVal = moment(value, "DD/MM/YYYY");
                        return momentVal;
                    },
                };
            }

            if (!this.hideTime && this.hideDate) {
                // Time Only
                return {
                    name: "time",
                    config: {
                        altFormat: "h:i K",
                    },
                    formatForModel(value) {
                        // as per https://www.django-rest-framework.org/api-guide/fields/#timefield
                        return moment(value).format("HH:mm:ss.SSSSSS"); // Full ISO String - might need to be Time only
                    },
                    parseInput(value) {
                        const momentVal = moment(value, "hh:mm A");
                        return momentVal;
                    },
                };
            }

            return {
                name: "unknown",
                config: {
                    // parseDate: parseDate,
                    altFormat: "j/n/Y h:i K",
                },
                formatForModel(value) {
                    return moment(value).toISOString(); // Full ISO String
                },
            };
        },
        _config() {
            // Get more form https://chmln.github.io/flatpickr/options/

            // common config for all
            var results = {
                inline: this.inline,
                noCalendar: this.hideDate,
                enableTime: !this.hideTime,
                wrap: !this.noButtons,
                locale: {
                    firstDayOfWeek: this.firstDayOfWeek,
                },
                minDate: this.minDate,
                maxDate: this.maxDate,
            };

            if (!this.inline) {
                var altInputClass = "form-control";

                if (this.state === true && this.invalidInput === false) {
                    altInputClass = altInputClass + " is-valid";
                }
                if (this.state === false || this.invalidInput === true) {
                    altInputClass = altInputClass + " is-invalid";
                }
                results.altInputClass = altInputClass;

                results.wrap = true; // set wrap to true only when using 'input-group'

                results.allowInput = true;
                results.altInput = true;
            }

            // Update the config with values for this mode
            results = Object.assign(results, this.mode.config);

            if (this.config) {
                results = Object.assign(results, this.config); // set the values from the config to override
            }

            return results;
        },
    },
    methods: {
        input_changed(input_value) {
            const parsedValue = this.mode.parseInput(input_value);
            if (parsedValue.isValid() || input_value === "") {
                this.invalidInput = false;

                // Value was changed in input.
                this.changed_date([parsedValue], input_value, null);
            } else {
                this.invalidInput = true;
            }
        },
        on_change(value) {
            this.$emit("input", value);
        },
        changed_date(selectedDates, dateStr, instance) {
            if (dateStr === "") {
                this.on_change(null);
            } else {
                if (selectedDates) {
                    var newVal = moment(selectedDates[0]);
                    var currentValue = moment(this.value);

                    if (!newVal.isSame(currentValue, "second")) {
                        this.on_change(
                            this.mode.formatForModel(selectedDates[0])
                        );
                    }
                }
            }
        },
    },
};
</script>

<style scoped></style>
