const StoreOrderList = () =>
  import(/* webpackChunkName: "main" */ "./pages/ListPage.vue");
const StoreOrderDetail = () =>
  import(/* webpackChunkName: "main" */ "./pages/FormPage.vue");
const StoreOrderIndex = () =>
  import(/* webpackChunkName: "main" */ "./pages/IndexPage.vue");

export default [
  {
    path: "/store_order",
    component: StoreOrderIndex,
    redirect: "/store_order/list",
    name: "store_order",
    children: [
      {
        path: "list",
        component: StoreOrderList,
        name: "store_order.list",
      },
      {
        path: "create",
        component: StoreOrderDetail,
        name: "store_order.create",
        props: { create: true },
      },
      {
        path: "create/:id/:productId/:stockId",
        component: StoreOrderDetail,
        name: "store_order.create_with_product",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: StoreOrderDetail,
        name: "store_order.edit",
        props: { create: false },
      },
    ],
  },
];
