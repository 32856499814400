import { Field, FIELD_TYPES, Model } from "../../vue-ocom/schema";

export default Model("store_contact", {
  verbose_name: "Store Contact",
  verbose_name_plural: "Store Contacts",
  fields: [
    Field("id", {
      label: "ID",
      primary_key: true,
      readonly: true,
      hide: false,
      field_type: FIELD_TYPES.AutoNumber,
    }),

    Field("active_start_date", {
      label: "Active Start Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return new Date();
      },
    }),
    Field("active_end_date", {
      label: "Active End Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("modified_date", {
      label: "Modified Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("created_date", {
      label: "Created Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("email", {
      label: "Email",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
    Field("login", {
      label: "Login",
      primary_key: false,
      readonly: false,
      required: true,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "auth.User",
      ref_display_field: "username",
      default: () => {
        return null;
      },
    }),
    Field("login__username", {
      label: "Login",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("type", {
      label: "Type",
      primary_key: false,
      readonly: false,
      required: true,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeContactType",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("type__description", {
      label: "Type",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("name", {
      label: "Name",
      primary_key: false,
      readonly: false,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
  ],
});
