const CodeProductCategoryList = () =>
  import(/* webpackChunkName: "main_app" */ "./pages/ListPage.vue");
const CodeProductCategoryDetail = () =>
  import(/* webpackChunkName: "main_app" */ "./pages/FormPage.vue");
const CodeProductCategoryIndex = () =>
  import(/* webpackChunkName: "main_app" */ "./pages/IndexPage.vue");

export default [
  {
    path: "/code_product_category",
    component: CodeProductCategoryIndex,
    redirect: "/code_product_category/list",
    name: "code_product_category",
    children: [
      {
        path: "list",
        component: CodeProductCategoryList,
        name: "code_product_category.list",
      },
      {
        path: "create",
        component: CodeProductCategoryDetail,
        name: "code_product_category.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodeProductCategoryDetail,
        name: "code_product_category.edit",
        props: { create: false },
      },
    ],
  },
];
