<template>
  <div id="app" class="d-flex flex-column h-100">
    <o-app-drawer :items="items" :admin_items="admin_items">
      <template v-slot:brand>
        <div>
          <div>
            <strong>BestFurn</strong>
            - Order Management
          </div>
        </div>
      </template>
      >
    </o-app-drawer>

    <o-app-content></o-app-content>

    <o-pwa-updater></o-pwa-updater>
    <o-app-footer :version="version" year="2020"></o-app-footer>
  </div>
</template>

<style>
#nprogress .bar {
  background: #1976d2 !important;
  height: 5px;
}

.white--text a {
  color: white;
}
</style>

<script>
import MENU_ITEMS from "./app/menu_items.js";
import ADMIN_ITEMS from "./app/admin_items.js";
import OAppDrawer from "./vue-ocom/components/layout/app/OAppDrawer.vue";
import OAppContent from "./vue-ocom/components/layout/app/OAppContent.vue";
import OAppFooter from "./vue-ocom/components/layout/app/OAppFooter.vue";
import * as CONFIG from "./config.js";
import OPwaUpdater from "./vue-ocom/components/layout/app/OPwaUpdater.vue";

export default {
  name: "App",
  components: { OPwaUpdater, OAppContent, OAppFooter, OAppDrawer },
  data: () => ({
    admin_items: ADMIN_ITEMS,
    items: MENU_ITEMS,
    version: CONFIG.version,
  }),
  props: {
    source: String,
  },
  created() {
    window.addEventListener("beforeunload", function (event) {
      if (window.lockLeave === true) {
        event.preventDefault();

        event.returnValue = "";
      } else {
        return false;
      }
    });
  },
};
</script>
