//Main routes containing home and error pages
import ONotFound from "../vue-ocom/pages/ONotFound.vue";
import HomePage from "./HomePage.vue";

export default [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/home/:group?",
    name: "homepage",
    component: HomePage,
  },
  {
    path: "/404",
    name: "404",
    component: ONotFound,
  },
  {
    path: "/marketing_material",
    name: "marketing_material",
    beforeEnter(to, from, next) {
      window.open(
        "https://bestfurnmarketing-my.sharepoint.com/:f:/g/personal/luke_day_bestfurnmarketing_com_au/EiObbH4r1PVDg8q5L4jKi4ABDRcrsBQkaO4h9VujEr8Y3Q",
        "_blank"
      );
      return false; // don't
    },
  },
  {
    path: "*",
    redirect: { name: "404" },
  },
];
