const CodeContactTypeList = () =>
  import(/* webpackChunkName: "admin" */ "./pages/ListPage.vue");
const CodeContactTypeDetail = () =>
  import(/* webpackChunkName: "admin" */ "./pages/FormPage.vue");
const CodeContactTypeIndex = () =>
  import(/* webpackChunkName: "admin" */ "./pages/IndexPage.vue");

export default [
  {
    path: "/code_contact_type",
    component: CodeContactTypeIndex,
    redirect: "/code_contact_type/list",
    name: "code_contact_type",
    children: [
      {
        path: "list",
        component: CodeContactTypeList,
        name: "code_contact_type.list",
      },
      {
        path: "create",
        component: CodeContactTypeDetail,
        name: "code_contact_type.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodeContactTypeDetail,
        name: "code_contact_type.edit",
        props: { create: false },
      },
    ],
  },
];
