import _ from "lodash";

export const apiUrl = process.env.API_URL;
export const root_url = process.env.VUE_APP_ROOT_API;
export const version = process.env.VUE_APP_VERSION;
export const userTokenStorageKey = "APP_USER_TOKEN";
export const authDisabled = false; /* Set to True to disable auth */
export const bridgeAngularJS = false;
export const permanent = true;
export const PermAdminRole = "Administrator";
export const DefaultPermissions = { home: { deny: false } };
export const Debug = process.env.NODE_ENV !== "production";
export const BaseURL = process.env.BASE_URL;

export const SentryURL = process.env.VUE_APP_SENTRY_DSN;

export const TurnOnComplexityChecking = false;
export const OldValidationLogic = true;

export const GoBackend = false;
export const CheckPageAccess = function (route, currentUser) {
  var result = {};
  if (currentUser && !_.isEmpty(currentUser)) {
    // We are logged in!
    var myGroups = _.keyBy(currentUser.groups, "name");

    if (myGroups["StoreUser"]) {
      // they are in the StoreUser Group
      if (!currentUser.profile.store_id) {
        // they have no store_id!!!
        result = {
          deny: false,
          message:
            "The account you are using has no existing linked Store record. " +
            "Some features might not work. Contact your system administrator for further " +
            "assistance.",
        };
        return result;
      }
    }

    if (currentUser) {
      if ((currentUser.groups || []).length === 0) {
        return {
          deny: true,
          message:
            "This account is not in any groups. " +
            "Contact your system administrator for further " +
            "assistance.",
        };
      }
      if (currentUser.groups.length > 1) {
        return {
          deny: false,
          message:
            "Your login is both a Store and BestFurn user. The system may not work properly",
        };
      }
    }
  }
  return null; // No problem!
};

export const DEFAULT_CODE_LIMIT = 4000;
