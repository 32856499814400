const StoreList = () =>
  import(/* webpackChunkName: "main" */ "./pages/ListPage.vue");
const StoreDetail = () =>
  import(/* webpackChunkName: "main" */ "./pages/FormPage.vue");
const StoreIndex = () =>
  import(/* webpackChunkName: "main" */ "./pages/IndexPage.vue");

export default [
  {
    path: "/store",
    component: StoreIndex,
    redirect: "/store/list",
    name: "store",
    children: [
      {
        path: "list",
        component: StoreList,
        name: "store.list",
      },
      {
        path: "create",
        component: StoreDetail,
        name: "store.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: StoreDetail,
        name: "store.edit",
        props: { create: false },
      },
    ],
  },
];
