const CodeFabricList = () =>
  import(/* webpackChunkName: "admin" */ "./pages/ListPage.vue");
const CodeFabricDetail = () =>
  import(/* webpackChunkName: "admin" */ "./pages/FormPage.vue");
const CodeFabricIndex = () =>
  import(/* webpackChunkName: "admin" */ "./pages/IndexPage.vue");

export default [
  {
    path: "/code_fabric",
    component: CodeFabricIndex,
    redirect: "/code_fabric/list",
    name: "code_fabric",
    children: [
      {
        path: "list",
        component: CodeFabricList,
        name: "code_fabric.list",
      },
      {
        path: "create",
        component: CodeFabricDetail,
        name: "code_fabric.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodeFabricDetail,
        name: "code_fabric.edit",
        props: { create: false },
      },
    ],
  },
];
