const ContainerList = () =>
  import(/* webpackChunkName: "main" */ "./pages/ListPage.vue");
const ContainerDetail = () =>
  import(/* webpackChunkName: "main" */ "./pages/FormPage.vue");
const ContainerIndex = () =>
  import(/* webpackChunkName: "main" */ "./pages/IndexPage.vue");

export default [
  {
    path: "/container",
    component: ContainerIndex,
    redirect: "/container/list",
    name: "container",
    children: [
      {
        path: "list",
        component: ContainerList,
        name: "container.list",
      },
      {
        path: "create",
        component: ContainerDetail,
        name: "container.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: ContainerDetail,
        name: "container.edit",
        props: { create: false },
      },
    ],
  },
];
