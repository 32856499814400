const StoreOrderLineList = () =>
  import(/* webpackChunkName: "main" */ "./pages/ListPage.vue");
const StoreOrderLineIndex = () =>
  import(/* webpackChunkName: "main" */ "./pages/IndexPage.vue");

export default [
  {
    path: "/store_order_line",
    component: StoreOrderLineIndex,
    redirect: "/store_order_line/list",
    name: "store_order_line",
    children: [
      {
        path: "list",
        component: StoreOrderLineList,
        name: "store_order_line.list",
      },
    ],
  },
];
