import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import OcomPlugin from "./vue-ocom/plugin.js";
//Router that merges all routes in app
import router from "./router.js";

//Use Plugins
import "./vue-ocom/plugins";

//Main Store
import store from "./store.js";

import "./registerServiceWorker";

//Integrate Ocom Global App
Vue.use(OcomPlugin);

//Config
Vue.config.productionTip = false;

//Vue
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
